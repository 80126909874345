import path from "path";

import { FFMpegInputFileSpec, FFMpegJob } from "~/context/FFMpegJobRunnerContext";
import { MediaFilter, MediaInput, StreamType, TimeRange } from "~/utils/ffmpegCmdBuilder";
import { Clip } from "~/utils/videoClips";

import { CaptionPhraseAudio } from "../captionProcessing";

export interface AudioEffect {
  url: string;
  startTime: number;
  audioClips?: Clip[];
  volume?: number;
}

export interface FfmpegExportSettings {
  sourceVideo: File;
  outputFileName: string;
  originalVideoUrl: URL;
  ignoreOriginalAudio?: boolean;
  backgroundAudioUrl?: URL | null;
  clips?: Clip[];
  phraseAudios?: CaptionPhraseAudio[] | null;
  audioEffects?: AudioEffect[] | null;
}

function urlExtension(url: URL, defaultExtension: string = ".mp3"): string {
  return path.extname(url.pathname) || defaultExtension;
}

function clipsToTimeRanges(clips?: Clip[]): TimeRange[] | undefined {
  const hasAnyDeletedClips = Boolean(clips?.some((clip) => clip.deleted));
  if (clips && hasAnyDeletedClips) {
    return clips
      .filter((clip) => !clip.deleted)
      .map((interval) => ({
        start: interval.startTime,
        end: interval.endTime,
      }));
  }
}

/**
 * Generates an FFMpeg export command for a video with dubbing and clips.
 *
 * @param sourceVideo - The generated video file that will be used as the base for the export.
 * @param outputFileName - The name of the output file.
 * @param originalVideoUrl - The URL of the original video file.
 * @param [ignoreOriginalAudio] - Whether to ignore the original video audio in case the parameter
 * `backgroundUrl` isn't given.
 * @param [backgroundAudioUrl] - The URL of the replacement background audio file.
 * @param [clips] - The clips that define the sections of the background audio to include.
 * @param [phraseAudios] - The dubbing phrases to overlay on the video.
 * @param [audioEffects] - The audio effects to add to this video.
 * @returns The FFMpeg export job command.
 * @remarks The command will mix the background audio and the dubbing phrases, and overlay them on
 * the source video.
 * @remarks If the `backgroundAudioUrl` is not provided, the original video audio will be used
 * unless `ignoreOriginalAudio` is set to `true`. In that case a silent audio stream will be used.
 */
export function getFfmpegExportCommand({
  sourceVideo,
  outputFileName,
  ignoreOriginalAudio,
  originalVideoUrl,
  backgroundAudioUrl,
  clips,
  phraseAudios,
  audioEffects,
}: FfmpegExportSettings): FFMpegJob {
  let inputFileName: string;
  let inputFormat: string | null = null;
  let audioTimeRanges: TimeRange[] | undefined;
  let audioCodec: string;
  let audioUrl: URL | null = null;
  let outputAudioStream = "input2";
  if (backgroundAudioUrl || !ignoreOriginalAudio) {
    // If there is audio, use the background audio or the original video audio
    audioUrl = backgroundAudioUrl || originalVideoUrl;
    audioTimeRanges = clipsToTimeRanges(clips);
    // If we are using the original video audio, attempt to avoid re-encoding the audio
    audioCodec = backgroundAudioUrl || audioTimeRanges?.length ? "aac" : "copy";
    inputFileName = `backgroundAudio${urlExtension(audioUrl)}`;
  } else {
    // No audio, se we use a null audio source
    // By using lavfi, we can use a filter as an input, allowing us to generate a silent audio
    // stream to use as a background audio source.
    inputFormat = "lavfi";
    inputFileName = "anullsrc=channel_layout=stereo:sample_rate=44100:duration=0.1";
    audioCodec = "aac"; // Filter inputs aren't encoded, so we need to encode the audio.
    audioTimeRanges = undefined;
  }
  const extraInputs: MediaInput[] = [];
  const extraInputSpecs: FFMpegInputFileSpec[] = [];
  const extraInputFilters: MediaFilter[] = [];
  const inputsToMix: { id: string; volume?: number }[] = [];
  const audiosToMix: AudioEffect[] = [
    ...(phraseAudios ?? [])
      .filter((phrase) => phrase.downloadUrl)
      .map((phraseAudio) => ({
        url: phraseAudio.downloadUrl,
        startTime: phraseAudio.startTime,
        audioClips: phraseAudio.audioClips,
      })),
    ...(audioEffects ?? []),
  ];
  audiosToMix.forEach((audioEffect, index) => {
    const phraseUrl = new URL(audioEffect.url);
    const inputId = `dubPhrase${index}`;
    const outputId = `${inputId}_delayed`;
    const fileName = `${inputId}${urlExtension(phraseUrl)}`;
    audioCodec = "aac"; // If any phrase is added, we need to re-encode the audio
    extraInputs.push({
      id: inputId,
      file: fileName,
      type: StreamType.Audio,
      timeRanges: clipsToTimeRanges(audioEffect.audioClips),
    });
    extraInputSpecs.push({ fileName: fileName, contents: phraseUrl });
    extraInputFilters.push({
      inputStreamIds: [inputId],
      commands: {
        name: "adelay",
        options: { delays: `${audioEffect.startTime * 1000}`, all: "1" },
      },
      outputStreamIds: [outputId],
    });
    inputsToMix.push({ id: outputId, volume: audioEffect.volume });
  });

  if (inputsToMix.length) {
    // Adds the background audio to the list of inputs to mix
    inputsToMix.unshift({ id: outputAudioStream });
    outputAudioStream = "dubbedAudio";
    // Uses the "amix" filter to mix the background audio and all of the sound clips.
    extraInputFilters.push({
      inputStreamIds: inputsToMix.map((input) => input.id),
      commands: {
        name: "amix",
        options: {
          inputs: `${inputsToMix.length}`,
          weights: inputsToMix.map((input) => (input.volume ?? 1).toFixed(2)).join(" "),
          normalize: "0",
        },
      },
      outputStreamIds: [outputAudioStream],
    });
  }
  const sourceVideoName = `sourceVideo${path.extname(sourceVideo.name)}`;

  return {
    task: {
      inputs: [
        { id: "input1", file: sourceVideoName, type: StreamType.Video },
        {
          id: "input2",
          file: inputFileName,
          type: StreamType.Audio,
          timeRanges: audioTimeRanges,
          format: inputFormat,
        },
        ...extraInputs,
      ],
      outputs: [
        {
          file: outputFileName,
          videoStreamId: "input1",
          videoCodec: "copy",
          audioStreamId: outputAudioStream,
          audioCodec,
          shortest: audioCodec !== "copy",
          padAudio: audioCodec !== "copy",
        },
      ],
      filters: extraInputFilters,
    },
    inputFiles: [
      { fileName: sourceVideoName, contents: sourceVideo },
      ...(audioUrl ? [{ fileName: inputFileName, contents: audioUrl }] : []),
      ...extraInputSpecs,
    ],
    outputFiles: [{ fileName: outputFileName, mimeType: "video/mp4" }],
  };
}
