import { satisfies } from "compare-versions";

import { BrowserType, getBrowserType, getBrowserVersion } from "~/hooks/useSupportedBrowserVersion";

const UNSUPPORTED_BROWSERS = ["Mozilla Firefox", "Apple Safari"];

export interface BrowserOverrides {
  enabled?: boolean;
  rangePattern?: string;
}

export type BrowserOverridesMap = Partial<Record<BrowserType, BrowserOverrides>>;

/**
 * Check if client-side exports are supported
 * @param overrides - Browser support overrides
 * @returns boolean - Whether client-side exports are supported
 */
export function isClientSideExportSupported(overrides?: BrowserOverridesMap) {
  // Always disabled server-side
  if (typeof window === "undefined") {
    return false;
  }

  // Check for blacklisted browsers
  const browserType = getBrowserType();
  const overridesForBrowser = overrides?.[browserType];
  const browserVersion = getBrowserVersion(browserType);
  const webCodecsSupported = Boolean(window?.VideoDecoder) && Boolean(window?.VideoEncoder);
  if (overridesForBrowser) {
    if (!overridesForBrowser.enabled) {
      return false;
    }
    if (overridesForBrowser.rangePattern && browserVersion) {
      try {
        if (!satisfies(browserVersion, overridesForBrowser.rangePattern)) {
          return false;
        }
      } catch (e) {
        console.error("Invalid range pattern for browser version", e);
        return false;
      }
    }
    // Regardless of overrides, will not work if webCodecs are not supported
    return webCodecsSupported;
  }
  if (UNSUPPORTED_BROWSERS.includes(browserType)) {
    return false;
  }
  // Check for Video transcoding support
  return webCodecsSupported;
}
